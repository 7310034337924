<template>
  <ShortcutWrapper
  @n-shortcut="parseTableAction({ actionType: 'open-form' })"
  :nShortcutRequiredClaim="UserClaims.TicketManager"
  :ticketContractMode="contractMode"
  :propShortcuts="this.shortcuts.shortcuts"
  :propInitiatingKey="this.shortcuts.initiatingKey"
  @shortcut="handleShortcut"
  >
    <BPCard
      dataTestId="ticket-table"
      :contractMode.sync="contractMode"
      :headerConfig="headerConfig"
      @update:contractMode-manual="clearContractFilter"
    >
      <TicketTable
      :loading="loading"
      :contractMode="contractMode"
      @search-btn-clicked="searchBtnClicked"
      @manage-consumption="manageConsumption"
      @ticket-action="parseTableAction"
      @reconcile="reconcileTickets"
      @refresh="refreshTickets"
      @post-loads="showPostedLoads"/>
      <v-dialog v-model="userInfoFormDialog" width="60vw">
        <UserInfoFormDialog
        @close="userInfoFormDialog = false"
        @user-info-added="sendTicketEmail"
        v-if="userInfoFormDialog"/>
      </v-dialog>
      <Dialog :stateId="dialogId"
      max-width="1800px"
      @dialog-closing="refreshTickets"
      @close="resetDialogs"
      @click:outside="resetDialogs"
      :enforcePersistence="persistPosting"
      >
        <TicketForm
        v-if="ticketForm"
        :propTicket="focusedTicket"
        :contractMode="contractMode"
        @ticket-changed="closeDialogsAndRefresh"
        @refresh="closeDialogsAndRefresh"
        />
        <TicketReconciliation
        @refresh="closeDialogsAndRefresh"
        v-if="ticketReconciliation"
        :isByproduct="contractMode?.value === 1"
        />
        <TractDetail
        :tractId="focusedTractId"
        v-if="focusedTractId != -1"
        @edit-tract="editTract"
        />
        <AdvancedSearch
        v-if="advancedSearch"
        :contractMode="contractMode"
        @search-clicked="advanceSearchTickets"
        />
        <Posting
        v-if="postingTickets"
        :contractMode="contractMode"
        @close="resetDialogs"
        @persist="persistPostHandler"
        />
        <ConfirmDialog
        v-if="deleting"
        :title="$t('deleteTicket')"
        :body="$t('deleteTicketBody', { ticketNumber: focusedTicket.ticketNumber })"
        @confirm="deleteItem"
        @cancel="resetDialogs"/>
        <TicketDetail
        v-if="ticketDetail"
        @edit-ticket="parseTableAction"
        :contractMode="contractMode"
        :ticketId="focusedTicket.ticketId"
        :propTicket="focusedTicket"/>
        <Consumption
        v-if="consumingTickets"
        :contractMode="contractMode"
        @close="closeDialogsAndRefresh"/>
        <InteractiveMap
        v-if="mapView"
        :ticket="focusedTicket"
        @map-closed="closeDialogsAndRefresh"
        :height="$vuetify.breakpoint.smAndDown ? '100%' : '80vh'"
        />
        <ContractDetail
        :contractId="focusedTicket.contractId"
        v-if="contractDetail"/>
      </Dialog>
    </BPCard>
  </ShortcutWrapper>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { ContractMode, UserClaims, ContractType, LiveExportType } from '@/utils/Enumerations.js'
import Cookies from 'js-cookie'
import { CookieKeys, LocalStorageKeys } from '@/utils/constants.js'
import RouterJump from '@/model/RouterJump.js'
import { uniqueDialogId } from '../utils/componentHelpers'
import { ticketsShortcuts } from '../utils/Shortcuts'
export default {
  name: 'Tickets',

  components: {
    BPCard: () => import('@/components/core/BPCard.vue'),
    TicketForm: () => import('@/components/ticket/TicketForm.vue'),
    InteractiveMap: () => import('@/components/maps/InteractiveMap.vue'),
    TicketTable: () => import('@/components/ticket/TicketTable.vue'),
    TicketReconciliation: () => import('@/components/ticket/ticket-reconciliation/TicketReconciliation.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    Posting: () => import('@/components/ticket/ticket-posting/Posting.vue'),
    ContractDetail: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    Consumption: () => import('@/components/ticket/ticket-consumption/Consumption.vue'),
    TicketDetail: () => import('@/components/ticket/ticket-details/TicketDetails.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    UserInfoFormDialog: () => import('@/components/settings-components/UserInfoFormDialog.vue'),
    AdvancedSearch: () => import('@/components/ticket/advanced-search/AdvancedSearch.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('tickets'),
    contractMode: null,
    deleting: false,
    ticketForm: false,
    mapView: false,
    ticketDetail: false,
    contractDetail: false,
    consumingTickets: false,
    postingTickets: false,
    persistPosting: false,
    ticketReconciliation: false,
    postingLoads: false,
    userInfoFormDialog: false,
    search: '',
    focusedTractId: -1,
    focusedTicket: undefined,
    advancedSearch: false,
    loading: false,
    shortcuts: ticketsShortcuts,
    UserClaims
  }),

  watch: {
    'contractMode.value' () {
      this.setContractMode(this.contractMode)
      Cookies.set(CookieKeys.TICKET_GRID_MODE, JSON.stringify(this.contractMode))
      this.removeFilter('phrase')
    },

    filter: {
      handler () {
        this.refreshTickets()
      },
      deep: true
    },
    async postingTickets (posting) {
      if (!posting) await this.refreshTickets()
    }
  },

  computed: {
    ...mapGetters('ticket', ['allTickets', 'filter']),
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('global', ['routerJump']),

    headerConfig () {
      switch (this.contractMode?.value) {
        case 0: return { title: this.$t('logsTickets'), subtitle: this.$t('logsTicketsDescription') }
        case 1: return { title: this.$t('byproductTickets'), subtitle: this.$t('byproductTicketsDescription') }
        case 2: return { title: this.$t('transferTickets'), subtitle: this.$t('transferTicketsDescription') }
        case 3: return { title: this.$t('logYardSaleTickets'), subtitle: this.$t('logYardSaleTicketsDescription') }
      }

      return null
    }
  },

  created () {
    if (this.routerJump) {
      const contractType = this.routerJump.sourceObject.contractType ?? this.routerJump.sourceObject.type
      this.setContractMode(this.contractModeFor(contractType))
      this.handleRouterJump()
    } else {
      const contractMode = Cookies.get(CookieKeys.TICKET_GRID_MODE)
      if (contractMode) {
        const parsed = JSON.parse(contractMode)
        const contractModeValue = parseInt(parsed.value)
        this.contractMode = isNaN(contractModeValue) ? ContractMode.Logs : ContractMode.forInt(contractModeValue)
      } else {
        this.contractMode = ContractMode.Logs
      }
      this.setContractMode(this.contractMode)
      if (this.contractMode === ContractMode.Logs) this.refreshTickets()
    }
  },

  beforeDestroy () {
    localStorage.removeItem(LocalStorageKeys.ADVANCED_SEARCH_LOGS_STATE)
    localStorage.removeItem(LocalStorageKeys.ADVANCED_SEARCH_BP_STATE)
    localStorage.removeItem(LocalStorageKeys.ADVANCED_SEARCH_TRANSFER_STATE)
    localStorage.removeItem(LocalStorageKeys.ADVANCED_SEARCH_LYS_STATE)
    this.removeFilter('phrase')
  },

  methods: {
    ...mapActions('ticket', ['fetchTickets', 'deleteTicket', 'removeFilter', 'triggerTicketExportEmail', 'setContractMode']),
    ...mapMutations('ticket', ['applyFilter']),
    ...mapActions('locations', ['fetchLocations']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('user', ['getUserInfo']),
    ...mapMutations('global', ['setRouterJump']),

    resetDialogs () {
      this.ticketForm = false
      this.mapView = false
      this.deleting = false
      this.postingTickets = false
      this.contractDetail = false
      this.consumingTickets = false
      this.ticketReconciliation = false
      this.advancedSearch = false
      this.ticketDetail = false
      this.focusedTractId = -1
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedTicket = undefined
    },

    showPostedLoads () {
      this.resetDialogs()
      this.postingTickets = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    manageConsumption () {
      this.resetDialogs()
      this.consumingTickets = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    reconcileTickets () {
      this.resetDialogs()
      this.ticketReconciliation = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    clearContractFilter () {
      this.removeFilter('contract')
    },

    handleShortcut (id) {
      switch (id) {
        case 'posting':
          this.showPostedLoads()
          break
        case 'advanced-search':
          this.parseTableAction({ actionType: id })
          break
        case 'inventory-operations':
          if (this.contractMode.value === 0 || this.contractMode.value === 1) {
            this.manageConsumption()
          }
          break
        case 'reconciliation':
          if (this.contractMode.value === 0 || this.contractMode.value === 1) {
            this.reconcileTickets()
          }
          break
      }
    },

    contractModeFor (contractType) {
      switch (contractType) {
        case ContractType.LogYardSale.value:
          return ContractMode.LogYardSale
        case ContractType.ByproductPurchase.value:
        case ContractType.ByproductSale.value:
          return ContractMode.Byproducts
        case ContractType.Transfer.value:
          return ContractMode.Transfers
        case ContractType.Production.value:
        case ContractType.WoodsSale.value:
        default:
          return ContractMode.Logs
      }
    },

    async parseTableAction (action) {
      this.resetDialogs()

      if (action.ticket) {
        this.focusedTicket = action.ticket
        this.contractMode = this.contractModeFor(this.focusedTicket.contractType)
      }

      switch (action.actionType) {
        case 'email-csv':
          if (this.userInfo === undefined) {
            await this.getUserInfo()
            this.verifyEmail()
          } else {
            this.verifyEmail()
          }
          break
        case 'view-detail':
          this.ticketDetail = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'contract-detail':
          this.contractDetail = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'view-tract':
          this.focusedTractId = action.ticket.tractId ? action.ticket.tractId : 1
          this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
          break
        case 'open-form':
          this.ticketForm = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        case 'open-map':
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          this.mapView = true
          break
        case 'delete-ticket':
          this.deleting = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '400px' })
          break
        case 'advanced-search':
          this.advancedSearch = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
          break
        default: break
      }
    },

    async deleteItem () {
      await this.deleteTicket(this.focusedTicket.ticketId)
      await this.closeDialogsAndRefresh()
    },

    async ignoreReqInFlight (networkCall) {
      try {
        await networkCall
      } catch (e) {
        if (e.message !== 'REQ_IN_FLIGHT') throw e
      }
    },

    async closeDialogsAndRefresh () {
      this.resetDialogs()
      await this.refreshTickets()
    },

    async refreshTickets () {
      this.loading = true

      await this.ignoreReqInFlight(this.fetchTickets(this.filter))
      await this.ignoreReqInFlight(this.fetchLocations({ includeByproductDecks: true }))

      this.loading = false
      if (this.allTickets.length >= 750) {
        this.setSnack(this.$t('maxTickets'))
      }
      this.handleRouterJump()
    },

    searchBtnClicked (search) {
      this.search = search

      if (search === '') {
        this.removeFilter('phrase')
      } else {
        this.applyFilter({
          phrase: search,
          name: 'phrase'
        })
      }
    },

    persistPostHandler (persist) {
      if (persist) this.persistPosting = persist
      else {
        requestAnimationFrame(_ => {
          this.persistPosting = persist
        })
      }
    },

    editTract () {
      const routerJump = new RouterJump('Tickets', 'Tracts', {
        tractId: this.focusedTractId
      })
      this.setRouterJump(routerJump)
      this.$router.push('tracts')
    },

    verifyEmail () {
      if (this.userInfo) {
        if (this.userInfo.person !== null && this.userInfo.person !== undefined) {
          this.sendTicketEmail()
          return
        }
      }
      this.userInfoFormDialog = true
    },

    async sendTicketEmail () {
      const requestObj = { type: null }

      switch (this.contractMode.value) {
        case ContractMode.Logs.value:
          requestObj.type = LiveExportType.ActiveLogsTickets.value
          break
        case ContractMode.Byproducts.value:
          requestObj.type = LiveExportType.ActiveByproductsTickets.value
          break
        case ContractMode.Transfers.value:
          requestObj.type = LiveExportType.ActiveTransferTickets.value
          break
        case ContractMode.LogYardSale.value:
          requestObj.type = LiveExportType.ActiveLogYardSaleTickets.value
          break
        default:
          throw new Error('Invalid export type.')
      }

      try {
        await this.triggerTicketExportEmail(requestObj)
        this.setSnack(this.$t('ticketEmailSent', { email: this.userInfo.person.email }))
      } finally {
        this.userInfoFormDialog = false
      }
    },

    advanceSearchTickets (query) {
      this.resetDialogs()

      this.applyFilter({
        name: 'advancedSearch',
        active: true,
        query: query
      })
    },

    handleRouterJump () {
      if (!this.routerJump) return

      if (this.routerJump.sourceView === 'Tract') {
        this.removeFilter('contract')
      }
      if (this.routerJump.sourceView === 'Contract') {
        this.applyFilter({
          name: 'contract',
          contractId: this.routerJump.sourceObject.contractId
        })
        this.contractMode = this.contractModeFor(this.routerJump.sourceObject.type)

        this.removeFilter('tract')
      } else if (this.routerJump.sourceView === 'TicketDetail') {
        this.parseTableAction({
          ticket: this.routerJump.sourceObject,
          actionType: 'open-form'
        })
      } else {
        this.focusedTicket = this.allTickets.find(t => this.routerJump.sourceObject.ticketId === t.ticketId)
        if (this.focusedTicket !== undefined) {
          this.editing = true
          this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
        }
      }
      this.setRouterJump(undefined)
    }
  }
}
</script>

<style>
  #progress-bar-table {
    position: absolute;
    left: 50%;
    top: 30%;
  }
</style>
